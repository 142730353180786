import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { App } from "vue";
import { environment } from "../environments/environmentLoader";
import { BackendError, isAxiosError } from "../http/http";
import { FailureOptions, FailureSource, FailureType } from "../lib/failure";
import { useSentry } from "../monitoring/sentry/sentryStore";

interface State {
  failure?: FailureMessage;
  additionalContext?: () => { [key: string]: string | undefined };
  undo: () => void;
  canUndo: () => boolean;
}

/**
 * Pinia store for centralizing all failure handling.
 */
export const useFailureStore = defineStore("failure", {
  state: (): State => ({
    /**
     * Failure state; when set, this will trigger the `FailureDialog`
     */
    failure: undefined,

    /**
     * Optional additional error context an app can register to be included.
     */
    additionalContext: undefined,

    /**
     * Per-app function to undo last action.
     */
    undo: () => null,

    /**
     * Per-app function to determine if last action can be undone.
     * @returns
     */
    canUndo: () => false,
  }),
  actions: {
    /**
     * Register as frontend error handler for an App.
     */
    registerApp(app: App) {
      if (!environment.requireBoolean("SENTRY_CAPTURES_ERROR_EVENTS")) {
        return;
      }
      app.config.errorHandler = (error) => this.fail(frontendFailure(errorToFailure(error)));
      window.addEventListener("error", (error) =>
        this.fail(frontendFailure(errorEventToFailure(error)))
      );
      window.addEventListener("unhandledrejection", (pre) =>
        this.fail(frontendFailure(promiseRejectionEventToFailure(pre)))
      );
      window.addEventListener("rejectionhandled", (pre) =>
        this.fail(frontendFailure(promiseRejectionEventToFailure(pre)))
      );
    },

    /**
     * Signal a backend failure message.
     * @param failure
     */
    backendFail(failure: FailureOptions) {
      this.fail(backendFailure(failure));
    },

    /**
     * Clear the current failure.
     */
    clearFailure() {
      this.failure = undefined;
    },

    /**
     * Helper function for handling failure.
     * @param failure
     */
    fail(failure: FailureMessage | undefined) {
      this.failure = failure;
    },
  },
});

function errorToFailure(error?: unknown): FailureOptions {
  const failure: FailureOptions = {
    type: FailureType.FrontendComponent,
    description: "An unhandled error occurred within a view component.",
    error,
  };
  return failure;
}

function errorEventToFailure(event: ErrorEvent): FailureOptions {
  const failure: FailureOptions = {
    type: FailureType.FrontendSystem,
    description: "An unhandled JavaScript error occurred.",
    error: event.error,
  };
  return failure;
}

function promiseRejectionEventToFailure(event: PromiseRejectionEvent): FailureOptions {
  const failure: FailureOptions = {
    type: FailureType.FrontendSystem,
    description: "An unhandled JavaScript error occurred in a promise.",
    error: event.reason,
  };
  return failure;
}

const titleMap = {
  [FailureType.Runner]: "Unhandled Runner failure",
  [FailureType.Planner]: "Unhandled Planner failure",
  [FailureType.Explorer]: "Explorer failure",
  [FailureType.FrontendSystem]: "Unhandled Front-End system error",
  [FailureType.FrontendComponent]: "Unhandled error in Front-End Component",
  [FailureType.Api]: "Unhandled API error",
};

function getTitle(failure: FailureOptions): string {
  return titleMap[failure.type];
}

function getMessage(failure: FailureOptions): string {
  const exception = failure.error;
  if (typeof exception === "string") {
    return exception;
  }
  return (exception as Error).message;
}

function frontendFailure(failure: FailureOptions): FailureMessage {
  if (failure.error instanceof BackendError && failure.error.failure) {
    return failure.error.failure;
  }
  const title = getTitle(failure);
  const message = getMessage(failure);
  const eventId = useSentry().sentry?.captureException(failure.error);
  const exception = failure.error instanceof Error ? failure.error : undefined;
  const result: FailureMessage = {
    title,
    message,
    type: failure.type,
    source: "frontend",
    description: failure.description,
    eventId,
    exceptionCause: exception?.cause,
    exceptionName: exception?.name,
    exceptionStack: exception?.stack,
    hideUndo: false,
  };
  return result;
}

function isAuthenticationError(error: AxiosError): boolean {
  return error.message === "Login required";
}

export function backendFailure(failure: FailureOptions): FailureMessage | undefined {
  let title = getTitle(failure);
  let message = getMessage(failure);

  if (!isAxiosError(failure.error)) {
    // We expect backend failures to be axiosErrors; if not, treat as frontend error.
    return frontendFailure(failure);
  }
  const axiosError = failure.error;
  if (isAuthenticationError(axiosError)) {
    // Ignore auth/n errors.
    // These should be handled by a redirect on the frontend.
    return;
  }
  const response = axiosError.response;
  const eventId = response?.data?.event_id;
  // We expect that backend errors have already been processed by Sentry.
  // If not, usually treat as frontend errors.
  let treatAsFrontendError = useSentry().enabled && !eventId;
  if (response) {
    if (response.status === 400 && response.data?.error === "UserMessageError") {
      title = response.data.title;
      message = response.data.message;
      treatAsFrontendError = false;
    } else if (response.status === 504) {
      title = "Claritype Backend Timeout";
      message = "The server is currently overloaded, please try again in a minute";
      treatAsFrontendError = false;
    }
  }
  if (treatAsFrontendError) {
    return frontendFailure(failure);
  }
  const result: FailureMessage = {
    title,
    message,
    type: failure.type,
    source: "backend",
    description: failure.description,
    eventId,

    endpoint: `${axiosError.config?.method?.toUpperCase()} ${axiosError.config?.url}`,
    request: axiosError.request,
    response: response?.data,
    exceptionCause: response?.data.cause || axiosError.cause,
    exceptionName: axiosError?.name,
    exceptionStack: axiosError.response?.data?.stack,

    hideUndo: failure.hideUndo || false,
  };
  return result;
}

/**
 * Model for the FailureDialog component.
 */
export interface FailureMessage {
  title: string;
  message: string;
  type: FailureType;
  source: FailureSource;

  /**
   * Sentry event ID; used for associating user feedback with errors.
   */
  eventId?: string;

  // Fields for the embedded error report.
  description: string;
  exceptionName?: string;
  endpoint?: string;
  exceptionCause?: unknown;
  exceptionStack?: string;
  request?: string;
  response?: unknown;

  /**
   * Should the undo button be hidden?
   */
  hideUndo: boolean;
}
