<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import {
  DiscreteDistributionVisualization,
  generateValues,
  visualizationTheme,
} from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { UseQueryResult } from "@/reader/composables/useQuery";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "@/common/lib/graph";

const props = defineProps<{
  visualization: DiscreteDistributionVisualization;
  results: UseQueryResult[];
  width?: number;
}>();
const { visualization, results, width } = toRefs(props);

const emit = defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const darkMode = inject(DarkMode) as Ref<boolean>;

interface Datum {
  index: number;
  category: GraphValue | GraphCompoundValue | null;
  categoryId: string;
  categoryName: [string, string];
  value: number;
  tooltip: Record<string, string>;
}

const data = computed(function () {
  const viz = visualization.value;
  return results.value.map(function (row, index): Datum {
    const values = generateValues(viz.config, row, viz.query);
    const categoryId = stringifyValueOrCompositeValue(values.category?.originalValue);
    const categoryName = stringifyValue((values.category_name ?? values.category)?.formattedValue);
    return {
      index,
      category: values.category?.originalValue ?? null,
      categoryId,
      categoryName: [categoryName, categoryId],
      value: values.value!.originalValue.value as number,
      tooltip: { [categoryName]: stringifyValue(values.value!.formattedValue) },
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  });
});

const height = computed(() => 30 + results.value.length * 25);

const spec: ComputedRef<vega.Spec> = computed(function () {
  const theme = visualizationTheme(darkMode.value);
  const spec: vega.Spec = {
    width: (width.value ?? 370) - 10,
    height: height.value,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@bar:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "linear",
        domain: { data: "table", field: "value" },
        range: "width",
        nice: true,
      },
      {
        name: "y",
        type: "band",
        domain: { data: "table", field: "categoryId" },
        range: "height",
        padding: 0.2,
      },
      {
        name: "y_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        format: "s",
        scale: "x",
        tickCount: 5,
        labelColor: theme.label,
        labelFontSize: 12,
      },
      {
        scale: "y",
        orient: "left",
        labelColor: theme.label,
        labelLimit: 120,
        domain: false,
        ticks: false,
        labelPadding: 5,
        encode: {
          labels: {
            update: {
              text: { signal: "scale('y_names', datum.value)[0]" },
            },
          },
        },
      },
    ],
    marks: [
      {
        name: "bar",
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            x: { scale: "x", value: 0 },
            x2: { scale: "x", field: "value" },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            fill: { value: theme.datum },
            tooltip: { signal: "datum.tooltip" },
          },
          hover: {
            fill: { value: theme.selectedDatum },
            cursor: { value: "pointer" },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  emit("select", visualization.value.config.category, category as GraphValue | null);
}
</script>
