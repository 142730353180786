import { Graph, GraphConcept, LinkDescriptor, linkPartner, ShapeMatch } from "@/common/lib/graph";
import {
  KnowledgeType,
  RELATED_LINK_TYPE,
  ROLE_LINK_TYPE,
  ShapeComponent,
  ShapeType,
} from "@/common/lib/knowledge";
import { chunk, last, partition } from "lodash";
import { QueryPathNode } from "../lib/query";
import useKnowledge from "./useKnowledge";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useGraph(graph: () => Graph) {
  const getConcept = (id: string) => graph().concepts.find((c) => c.id === id) as GraphConcept;
  const getConceptsOfType = (type: string) => graph().concepts.filter((c) => c.type === type);
  const getLink = (id: string) => graph().links.find((l) => l.id == id);
  const getLinksWith = (conceptId: string) =>
    graph().links.filter((link) => link.from == conceptId || link.to == conceptId);
  const getLinksFrom = (conceptId: string) =>
    graph().links.filter((link) => link.from == conceptId);
  const getLinksTo = (conceptId: string) => graph().links.filter((link) => link.to == conceptId);

  function getLinkPartners(conceptId: string, descriptor: LinkDescriptor) {
    const links = graph().links;
    switch (descriptor) {
      case LinkDescriptor.AsA:
        return links
          .filter((l) => l.from == conceptId && l.type == ROLE_LINK_TYPE)
          .map((l) => l.to);
      case LinkDescriptor.RoleOf:
        return links
          .filter((l) => l.to == conceptId && l.type == ROLE_LINK_TYPE)
          .map((l) => l.from);
      case LinkDescriptor.RelatedTo: {
        return [
          ...links
            .filter((l) => l.from == conceptId && l.type == RELATED_LINK_TYPE)
            .map((l) => l.to),
          ...links
            .filter((l) => l.to == conceptId && l.type == RELATED_LINK_TYPE)
            .map((l) => l.from),
        ];
      }
    }
  }

  function getMetaPath(sourceConceptType: string, destConceptType: string): QueryPathNode[] | null {
    const sourceConceptId = getConceptsOfType(sourceConceptType)[0].id;
    const pathQueue: string[][] = [[sourceConceptId]];
    const exploredConcepts = [sourceConceptId];
    while (pathQueue.length > 0) {
      pathQueue.sort((a, b) => b.length - a.length);
      const path = pathQueue.pop()!;
      const conceptId = last(path)!;
      if (getConcept(conceptId).type === destConceptType) {
        // Remove the source concept ID, and convert concept IDs to types
        return chunk(path.slice(1), 2).map(([ld, cid]) => ({
          concept_type: getConcept(cid).type,
          link_descriptor: ld as LinkDescriptor,
        }));
      }
      const links = getLinksWith(conceptId);
      for (const link of links) {
        const partnerConceptId = linkPartner(link, conceptId);
        if (!exploredConcepts.includes(partnerConceptId)) {
          exploredConcepts.push(partnerConceptId);
          let linkDescriptor: LinkDescriptor;
          if (link.type == RELATED_LINK_TYPE) {
            linkDescriptor = LinkDescriptor.RelatedTo;
          } else {
            linkDescriptor = link.from === conceptId ? LinkDescriptor.AsA : LinkDescriptor.RoleOf;
          }
          pathQueue.push([...path, linkDescriptor, partnerConceptId]);
        }
      }
    }
    return null;
  }

  // This finds shapes where the passed concept type is either the actor or the subject
  function findShapeMatches(baseConceptId: string): ShapeMatch[] {
    const { getKnowledgeItemsOfType, isAncestorOf } = useKnowledge();
    const matches = [];
    const baseConcept = getConcept(baseConceptId);
    const nearLinks = getLinksWith(baseConcept.id);
    const shapes = getKnowledgeItemsOfType(KnowledgeType.Shape) as ShapeType[];
    for (const shape of shapes) {
      let baseComponent;
      // Step 1: Do we match either the shape's subject or its actor?
      if (isAncestorOf(baseConcept.type, shape.subject)) {
        baseComponent = ShapeComponent.Subject;
      } else if (isAncestorOf(baseConcept.type, shape.actor)) {
        baseComponent = ShapeComponent.Actor;
      } else {
        continue;
      }
      for (const nearLink of nearLinks) {
        // Step 2: Does our neighbor match the shape's role?
        const neighborId = linkPartner(nearLink, baseConcept.id);
        const neighbor = getConcept(neighborId);
        if (!isAncestorOf(shape.role, neighbor.type)) continue;
        // ...and are we linked to it the way we'd expect?
        if (baseComponent === ShapeComponent.Subject) {
          if (nearLink.type !== RELATED_LINK_TYPE) continue;
        } else {
          if (nearLink.type !== ROLE_LINK_TYPE) continue;
          if (nearLink.from !== baseConcept.id) continue;
        }
        for (const farLink of getLinksWith(neighborId)) {
          // Step 3: does the role's neighbor match the other end of the shape?
          const roleNeighborId = linkPartner(farLink, neighborId);
          const roleNeighbor = getConcept(roleNeighborId);
          if (baseComponent === ShapeComponent.Subject) {
            if (!isAncestorOf(roleNeighbor.type, shape.actor)) continue;
            if (farLink.type !== ROLE_LINK_TYPE) continue;
            if (farLink.from !== roleNeighbor.id) continue;
          } else {
            if (!isAncestorOf(roleNeighbor.type, shape.subject)) continue;
            if (farLink.type !== RELATED_LINK_TYPE) continue;
          }
          matches.push({
            shape,
            baseComponent,
            nearLink,
            farLink,
            role: neighbor,
            opposing: roleNeighbor,
          });
        }
      }
    }
    return matches;
  }

  function metagraphWithoutRecords(): Graph {
    const { isRecordConceptType } = useKnowledge();
    const [records, nonRecords] = partition(graph().concepts, (c) => isRecordConceptType(c.type));
    const recordIds = records.map((concept) => concept.id);
    const links = graph().links.filter(
      (link) => !recordIds.includes(link.from) && !recordIds.includes(link.to)
    );
    return {
      concepts: nonRecords,
      links,
    };
  }

  return {
    getConcept,
    getConceptsOfType,
    getLink,
    getLinksWith,
    getLinksFrom,
    getLinksTo,
    getLinkPartners,
    getMetaPath,
    findShapeMatches,
    metagraphWithoutRecords,
  };
}
