import { onMounted, onUnmounted } from "vue";
import { KeyCommand, ModifierKey } from "../lib/modifierKeys";

export function useKeyHandler() {
  interface Handler {
    key: string;
    modifiers: ModifierKey[];
    action: () => unknown;
    consume: boolean;
    skipInputs: boolean;
  }

  const handlers = new Map<string, Handler>();

  function registerHandler(
    keyCommand: KeyCommand,
    action: () => unknown,
    consume = false,
    skipInputs = true
  ) {
    const key = keyCommand.key;
    const modifiers = keyCommand.modifiers || [];
    modifiers.sort();
    const compoundKey = buildKey(key, modifiers);
    const handler = {
      key,
      modifiers,
      action,
      consume,
      skipInputs,
    };
    handlers.set(compoundKey, handler);
  }

  function getModifiers(event: KeyboardEvent): ModifierKey[] {
    const modifiers: ModifierKey[] = [];
    if (event.altKey) {
      modifiers.push("alt");
    }
    if (event.ctrlKey) {
      modifiers.push("control");
    }
    if (event.metaKey) {
      modifiers.push("meta");
    }
    if (event.shiftKey) {
      modifiers.push("shift");
    }
    return modifiers;
  }

  /**
   * Build a compound key using key and modifiers.
   * Note: modifiers must be pre-sorted alphabetically before this function.
   * @param key
   * @param modifiers
   * @returns
   */
  function buildKey(key: string, modifiers: ModifierKey[]): string {
    return key + "-" + modifiers.join("-");
  }

  // a composable can update its managed state over time.
  function handleKey(event: KeyboardEvent) {
    const key = buildKey(event.code, getModifiers(event));
    const handler = handlers.get(key);
    if (!handler) {
      return;
    }
    // Don't undo while in a text input
    if (handler.skipInputs && event.target) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eventTarget = event.target as any;
      if (eventTarget.matches("input") || eventTarget.matches("textarea")) {
        return;
      }
    }
    if (handler.consume) {
      event.preventDefault();
    }
    handler.action();
  }

  onMounted(() => window.addEventListener("keydown", handleKey));
  onUnmounted(() => window.removeEventListener("keydown", handleKey));

  return { registerHandler };
}
