<template>
  <Select
    :label="label"
    :options="concepts"
    v-model="value"
    :option-value="knowledgeItemId"
    :placeholder="placeholder"
    :clearable="false"
  >
    <template v-slot:option-data="concept: ConceptType">
      <div class="flex flex-col justify-between">
        <div class="flex flex-row items-center gap-10">
          <div
            class="rounded-full p-[3px]"
            :style="{ backgroundColor: color(knowledgeItemId(concept)) }"
          >
            <Icon :name="concept.icon_name" color="gray2" size="m" />
          </div>
          {{ concept.label }}
        </div>
        <span v-if="includeConceptId" class="text-gray-700 dark:text-gray-500">{{
          knowledgeItemId(concept)
        }}</span>
      </div>
    </template>
  </Select>
</template>

<script lang="ts" setup>
import { useKnowledgeStore } from "@/common/stores/knowledgeStore";
import { computed, ComputedRef } from "vue";
import { ConceptType, knowledgeItemId, KnowledgeType } from "../lib/knowledge";
import Icon from "./Icon.vue";
import { sortBy } from "lodash";
import Select from "./Select.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { storeToRefs } from "pinia";

const { conceptColors } = storeToRefs(useExploreStore());

const knowledgeStore = useKnowledgeStore();

const props = defineProps<{
  label?: string;
  modelValue?: string;
  placeholder?: string;
  includeAdhoc?: boolean;
  conceptIds?: string[];
  includeConceptId?: boolean;
}>();
const emit = defineEmits(["update:modelValue"]);

const concepts = computed(() => {
  let allConcepts = conceptMap.value;
  if (props.conceptIds) {
    const conceptSet = new Set(props.conceptIds);
    allConcepts = allConcepts.filter(([k, _]) => conceptSet.has(k));
  }
  return sortBy(
    allConcepts.map(([_, v]) => v),
    (c) => c.label
  );
});

const conceptMap: ComputedRef<Array<[string, ConceptType]>> = computed(() => {
  if (props.includeAdhoc) {
    return Object.entries(knowledgeStore.combinedKnowledge).flatMap(([k, v]) =>
      v.type === KnowledgeType.Concept ? [[k, v]] : []
    );
  }
  return Object.entries(knowledgeStore.concepts);
});

function color(conceptType: string) {
  return conceptColors.value[conceptType] ?? "white";
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
