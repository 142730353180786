import { environment } from "@/common/environments/environmentLoader";
import { httpClient } from "@/common/http/http";
import { FailureSource } from "@/common/lib/failure";
import { SendFeedbackParams } from "@sentry/types";
import * as sentry from "@sentry/vue";
import { App } from "vue";

export class Sentry {
  public static isEnabled(): boolean {
    return environment.requireBoolean("SENTRY_ENABLED");
  }

  private user?: { id?: string; email?: string; name?: string };

  constructor(private app: App) {}

  public initialize() {
    const integrations = [
      sentry.browserTracingIntegration(),
      sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      sentry.globalHandlersIntegration({
        onunhandledrejection: false,
        onerror: false,
      }),
    ];
    if (environment.requireBoolean("SENTRY_USER_FEEDBACK_ENABLED")) {
      const feedbackIntegration = sentry.feedbackIntegration({
        colorScheme: "dark",
        showBranding: false,
        useSentryUser: { email: "email", name: "name" },
        themeDark: {
          fontFamily: "Inter, sans-serif",
          background: "#2c2c2c",
          backgroundHover: "#222222",
          foreground: "#e8e8e8",
          submitBackground: "#2c2c2c",
          submitBackgroundHover: "#222222",
          submitBorder: "#f75e0e",
          inputBackground: "#222222",
          inputOutlineFocus: "#f75e0e",
        },
      });
      integrations.push(feedbackIntegration);
    }
    const tracePropagationTargets: (string | RegExp)[] = [];
    environment
      .get("SENTRY_TRACE_PROPAGATION_TARGETS", "")
      .split(/, /)
      .forEach((target) => tracePropagationTargets.push(target));
    environment
      .get("SENTRY_TRACE_PROPAGATION_TARGET_REGEXES", "")
      .split(/, /)
      .map((re) => new RegExp(re))
      .forEach((target) => tracePropagationTargets.push(target));
    sentry.init({
      app: this.app,
      dsn: environment.require("SENTRY_DSN"),
      environment: environment.require("ENVIRONMENT_NAME"),
      integrations,
      // Performance Monitoring
      tracesSampleRate: environment.requireNumber("SENTRY_TRACES_SAMPLE_RATE"),
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets,
      // Session Replay
      replaysSessionSampleRate: environment.requireNumber("SENTRY_REPLAYS_SESSION_SAMPLE_RATE"),
      replaysOnErrorSampleRate: environment.requireNumber("SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE"),
      beforeSend(event: sentry.ErrorEvent, hint: sentry.EventHint) {
        if (environment.requireBoolean("SENTRY_ONLY_LOG")) {
          // Development-mode logging.
          // eslint-disable-next-line no-console
          console.log("Sentry event is", event);
          // eslint-disable-next-line no-console
          console.log("Sentry hint is", hint);
          return null;
        }
        return event;
      },
    });
  }

  public setUser(user: { id?: string; email?: string; name?: string }) {
    sentry.setUser(user);
    this.user = user;
    // add email as a tag as well, we want to see if in Slack alerts
    this.setTag("email", user.email);
  }

  public clearUser() {
    sentry.setUser(null);
    this.user = undefined;
    this.setTag("email", undefined);
  }

  /**
   * Set key:value that will be sent as tags data with the event.
   *
   * Can also be used to unset a tag, by passing `undefined`.
   *
   * @param key String key of tag
   * @param value Value of tag
   */
  public setTag(key: string, value: Primitive) {
    sentry.setTag(key, value);
  }

  public registerAttachmentProvider(provider: () => Attachment | undefined) {
    sentry.addEventProcessor((event, hint) => {
      const attachment = provider();
      if (attachment) {
        hint.attachments = [attachment];
      }
      return event;
    });
  }

  // Parent API takes any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public captureException(exception: any, hint?: sentry.EventHint): string {
    return sentry.captureException(exception, hint);
  }

  /**
   * Capture user feedback for a particular error event, and send to Sentry.
   *
   * @param source "frontend" or "backend"; used to determine how to route the request
   * @param eventId ID of the error event
   * @param comments user feedback comments
   */
  public captureUserFeedback(source: FailureSource, eventId: string, comments: string) {
    switch (source) {
      case "frontend":
        return this.captureFrontendUserFeedback(eventId, comments);
      case "backend":
        return this.captureBackendUserFeedback(eventId, comments);
    }
  }

  public sendUserFeedback(message: string) {
    const feedback: SendFeedbackParams = {
      message,
      name: this.user?.name || "Anonymous User",
      email: this.user?.email,
    };
    return sentry.sendFeedback(feedback);
  }

  private captureFrontendUserFeedback(eventId: string, message: string) {
    const userFeedback: SendFeedbackParams = {
      associatedEventId: eventId,
      name: this.user?.name || "Anonymous User",
      email: this.user?.email,
      message,
    };
    sentry.captureFeedback(userFeedback, { includeReplay: true });
  }

  private async captureBackendUserFeedback(eventId: string, comments: string) {
    await httpClient.post("/api/user-feedback", { event_id: eventId, comments });
  }
}

export interface Attachment {
  data: string | Uint8Array;
  filename: string;
  contentType?: string;
}

export type Primitive = number | string | boolean | bigint | symbol | null | undefined;
