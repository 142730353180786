export enum AsyncStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Succeeded = "succeeded",
  Failed = "failed",
}

export interface AsyncNotStarted {
  status: AsyncStatus.NotStarted;
}

export interface AsyncInProgress {
  status: AsyncStatus.InProgress;
  message: string;
}

export interface AsyncSucceeded<T> {
  status: AsyncStatus.Succeeded;
  result: T;
}

export interface AsyncFailed {
  status: AsyncStatus.Failed;
  message: string;
}

export type Async<T> = AsyncNotStarted | AsyncInProgress | AsyncSucceeded<T> | AsyncFailed;

export function asyncNotStarted(): AsyncNotStarted {
  return { status: AsyncStatus.NotStarted };
}

export function asyncInProgress(message?: string): AsyncInProgress {
  return {
    status: AsyncStatus.InProgress,
    message: message || "In progress",
  };
}

export function asyncSucceeded<T>(result: T): AsyncSucceeded<T> {
  return {
    status: AsyncStatus.Succeeded,
    result,
  };
}

export function asyncFailed(message: string): AsyncFailed {
  return {
    status: AsyncStatus.Failed,
    message,
  };
}

export function asyncValue<T>(async: Async<T>): T | null {
  if (async.status === AsyncStatus.Succeeded) return async.result;
  return null;
}

export function asyncResultOr<T>(async: Async<T>, defaultResult: T): T {
  if (async.status === AsyncStatus.Succeeded) return async.result;
  return defaultResult;
}

export function asyncHasValue(async: Async<unknown>) {
  return async.status === AsyncStatus.Succeeded;
}

export function asyncFailureMessage(async: Async<unknown>) {
  if (async.status === AsyncStatus.Failed) return async.message;
  return null;
}
