<template>
  <div class="h-full bg-gray-200 dark:bg-gray-800">
    <div class="user-details">
      <div class="flex bg-gray-100 dark:bg-gray-900">
        <TabSelect :tabs="availableTabs" v-model="selectedTab" />
      </div>
      <div
        class="m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
        v-if="selectedTab === 'user-settings'"
      >
        <div class="flex flex-col items-start gap-15" v-if="user">
          <div class="text-xl">Name</div>
          <div>{{ user.name }}</div>
          <div><TextButton label="Update name" @click="editUserName" /></div>
        </div>
        <div class="flex flex-col items-start gap-15" v-if="user">
          <div class="text-xl">Email</div>
          <div class="flex flex-col gap-5">
            <span>{{ user.email }}</span
            ><span v-if="user.emailVerified" class="text-500 italic">verified</span>
          </div>
        </div>
        <div class="flex flex-col items-start gap-15">
          <div class="text-xl">Session</div>
          <TextButton label="Logout" @click="logout" />
        </div>
      </div>
    </div>
    <div
      class="m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
      v-if="selectedTab === 'connection'"
    >
      <ConnectionSettings />
    </div>
  </div>

  <Dialog
    v-if="editingUserName !== undefined"
    title="Update name"
    success-label="Update"
    @succeeded="finishEditUserName"
    @cancelled="editingUserName = undefined"
  >
    <Textbox label="Name" v-model="editingUserName" :autoselect="true" />
  </Dialog>
</template>

<script lang="ts" setup>
import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import TabSelect from "@/common/components/TabSelect.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useSettingsStore, Routes } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();

const userStore = useUserStore();
const router = useRouter();
const { user } = storeToRefs(userStore);

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

watch(selectedTab, (newValue) => {
  if (newValue === "connection") {
    router.replace({ name: Routes.Connections });
  } else {
    router.replace({ name: Routes.UserSettings });
  }
});
onMounted(() => {
  userStore.reloadUser();
});
</script>
