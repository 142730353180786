import { environment } from "@/common/environments/environmentLoader";
import { httpClient } from "@/common/http/http";
import { FailureType } from "@/common/lib/failure";
import { User } from "@/common/lib/user";
import { useFailureStore } from "@/common/stores/failureStore";
import { defineStore } from "pinia";

interface State {
  user?: User;
  enabled: boolean;
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: undefined,
    enabled: environment.require("AUTH_TYPE") == "auth0",
  }),
  actions: {
    async reloadUser() {
      if (!this.enabled) {
        return;
      }
      const response = await httpClient.get("/api/user/settings");
      this.user = response.data.user;
    },
    async updateUserName(userName: string): Promise<boolean> {
      try {
        const params = { name: userName };
        await httpClient.patch(`/api/user/settings`, params);
        await this.reloadUser();
        return true;
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to update user name",
          error,
          hideUndo: true,
        });
        return false;
      }
    },
  },
});
