<template>
  <div
    v-if="mode === 'create'"
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
  >
    <div
      class="item-start flex flex-col justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select your data provider: </span>
      <div v-if="!selectedProvider" class="m-10 flex flex-col justify-between gap-10">
        <ProviderButton
          img-src="/images/providers/dbx/databricks-wide.svg"
          height="40px"
          @click="selectedProvider = 'dbx'"
        />
        <ProviderButton
          img-src="/images/providers/snowflake/snowflake-wide.svg"
          height="50px"
          @click="selectedProvider = 'snowflake'"
        />
        <ProviderButton
          img-src="/images/providers/starrocks/starrocks-wide.svg"
          height="35px"
          :disabled="true"
        />
      </div>
      <div v-else class="m-10 flex flex-col justify-between gap-10">
        <ProviderButton
          v-if="selectedProvider === 'dbx'"
          img-src="/images/providers/dbx/databricks-wide.svg"
          height="40px"
          :static="true"
        />
        <ProviderButton
          v-if="selectedProvider === 'snowflake'"
          img-src="/images/providers/snowflake/snowflake-wide.svg"
          height="50px"
          :static="true"
        />
      </div>
    </div>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <div></div>
      <TextButton
        label="Cancel"
        @click="cancelEditWorkspace()"
        v-if="selectedProvider === undefined"
      />
    </div>
  </div>
  <DbxEditConnectionSettings
    v-if="selectedProvider === 'dbx'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
  <SnowflakeEditConnectionSettings
    v-if="selectedProvider === 'snowflake'"
    :current-workspace="workspaceSettings"
    :mode="mode"
    @cancel="cancelEdit"
    @save-workspace="cancelEditWorkspace()"
  />
</template>

<script lang="ts" setup>
import TextButton from "@/common/components/TextButton.vue";
import { ref, onMounted } from "vue";
import DbxEditConnectionSettings from "./DbxEditConnectionSettings.vue";
import SnowflakeEditConnectionSettings from "./SnowflakeEditConnectionSettings.vue";
import { useDatabricksStore, FullWorkspace } from "@/common/stores/databricksStore";
import ProviderButton from "./ProviderButton.vue";

const props = defineProps<{ mode: "edit" | "create"; workspaceId?: string }>();
const emits = defineEmits(["saveWorkspace"]);

const selectedProvider = ref<string | undefined>();
const databricksStore = useDatabricksStore();

const workspaceSettings = ref<FullWorkspace>();

function cancelEdit() {
  if (props.mode === "edit") {
    cancelEditWorkspace();
  } else {
    selectedProvider.value = undefined;
  }
}

function cancelEditWorkspace() {
  emits("saveWorkspace");
}

onMounted(async () => {
  if (props.mode === "create") {
    return;
  }
  if (props.workspaceId) {
    const workspace = await databricksStore.loadFullWorkspace(props.workspaceId);
    workspaceSettings.value = workspace;
    selectedProvider.value = workspace.metadata?.provider;
  }
});
</script>
