import { httpClient } from "@/common/http/http";
import { useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { defineStore } from "pinia";

interface State {
  loaded: boolean;
  connected?: boolean;
  warehouses: Warehouse[];
  currentWarehouse?: Warehouse;
  currentWorkspaceId?: string;
  editInProgress: boolean;
  initialized: boolean;
  workspaces?: Workspace[];
  currentWorkspace?: Workspace;
}

interface DatabricksStatus {
  connected: boolean;
  warehouse?: Warehouse;
}

export interface Workspace {
  id: string;
  nickname: string;
  connected: boolean;
  current_warehouse_id?: string;
  provider: ProviderType;
}

export interface FullWorkspace {
  metadata: Workspace;
  credentials: unknown;
}

export enum ProviderType {
  Databricks = "dbx",
  Snowflake = "snowflake",
}

export interface Warehouse {
  id: string;
  name: string;
  sql_path: string;
  cluster_size: string;
  creator_name: string;
  state: "DELETED" | "DELETING" | "RUNNING" | "STARTING" | "STOPPED" | "STOPPING";
  enable_photon: boolean;
  enable_serverless_compute: boolean;
}

interface ListWarehousesResponse {
  warehouses: Warehouse[];
}

export const useDatabricksStore = defineStore("databricks", {
  state: (): State => ({
    loaded: false,
    connected: undefined,
    warehouses: [],
    currentWarehouse: undefined,
    editInProgress: false,
    initialized: false,
    currentWorkspace: undefined,
    workspaces: undefined,
    currentWorkspaceId: undefined,
  }),
  actions: {
    cancel() {
      const connected = this.connected;
      this.$reset();
      this.connected = connected;
    },
    initializeWorkspaceState() {
      if (!this.initialized) {
        this.initialized = true;
        this.reloadWorkspaceState();
      }
    },
    async reloadWorkspaceState() {
      const [workspaceId] = await Promise.all([this.loadWorkspace(), this.listWorkspaces()]);
      if (!workspaceId) {
        return;
      }
      this.listWarehouses(workspaceId);
      this.initialized = true;
    },
    async saveWorkspace(workspace: FullWorkspace) {
      await httpClient.post("/api/workspaces", workspace);
      await this.reloadWorkspaceState();
    },
    async getStatus(): Promise<DatabricksStatus> {
      const response: AxiosResponse<DatabricksStatus> =
        await httpClient.get("/api/workspaces/status");
      return response.data;
    },
    async checkConnected(): Promise<boolean> {
      const status = await this.getStatus();
      this.connected = status.connected;
      return this.connected;
    },
    async loadFullWorkspace(workspaceId: string): Promise<FullWorkspace> {
      const response = await httpClient.get(`/api/workspaces/${workspaceId}`);
      return response.data.workspace;
    },
    async loadWorkspace(): Promise<string | undefined> {
      const response = await httpClient.get("/api/workspaces/current");
      this.currentWorkspace = response.data.workspace;
      if (isEmpty(this.currentWorkspace)) {
        this.editInProgress = true;
        return;
      }
      this.currentWorkspaceId = this.currentWorkspace.id;
      this.loadFullWorkspace(this.currentWorkspaceId);
      this.loaded = true;
      return this.currentWorkspaceId;
    },
    async listWorkspaces() {
      const response = await httpClient.get("/api/workspaces");
      this.workspaces = response.data.workspaces || [];
    },
    updateWarehouse(workspaceId: string, warehouse_id: string) {
      return httpClient.post(`/api/workspaces/${workspaceId}/warehouses/current`, { warehouse_id });
    },
    async deleteCurrentWorkspace() {
      const host = this.currentWorkspaceId;
      if (host === undefined) {
        return;
      }
      await httpClient.delete(`/api/workspaces/${host}`);
      this.reloadWorkspaceState();
    },
    async selectWorkspace(workspaceHostName: string) {
      if (!workspaceHostName) {
        return;
      }
      if (this.currentWorkspaceId === workspaceHostName) {
        return;
      }
      const sourceBrowserStore = useSourceBrowserStore();
      await httpClient.post(`/api/workspaces/current/${workspaceHostName}`);
      // Reload
      this.reloadWorkspaceState();
      sourceBrowserStore.loadSystemTables(workspaceHostName);
    },
    async cancelEditWorkspace() {
      this.editInProgress = false;
      await this.reloadWorkspaceState();
    },
    async getCurrentWarehouse(): Promise<Warehouse | undefined> {
      const status = await this.getStatus();
      this.currentWarehouse = status.warehouse;
      return this.currentWarehouse;
    },
    async listWarehouses(workspaceId: string): Promise<Warehouse[]> {
      const response = await httpClient.get(`/api/workspaces/${workspaceId}/warehouses`);
      const config: ListWarehousesResponse = response.data;
      this.warehouses = config.warehouses;
      return this.warehouses;
    },
  },
});
