<template>
  <div class="relative mb-10">
    <div
      class="absolute left-[-21px] flex h-[26px] items-center bg-gray-100 dark:bg-gray-800"
      v-if="!isRoot"
    >
      <Disclosure :expanded="isExpanded" @toggle="toggleExpanded" />
    </div>
    <div class="flex items-center">
      <div class="rounded-full p-[3px]" :style="{ backgroundColor: color }">
        <Icon class="icon" :name="iconName" size="l" color="gray2" />
      </div>
      <Dropdown placement="right" class="mr-10 flex basis-full cursor-pointer items-center">
        <template #popper>
          <Menu :items="menuItems" v-close-popper />
        </template>
        <div class="ml-5 basis-full">
          <span class="role-summary" v-if="linkDescription">{{ linkDescription }}&nbsp;</span>
          <span class="font-bold">{{ name }}</span>
        </div>
        <IconButton name="chevron-down" size="xs" />
      </Dropdown>
    </div>
    <div
      class="ml-[12px] mt-5 border-l border-l-gray-200 pl-10 dark:border-l-gray-700"
      v-if="isExpanded"
    >
      <TreeDerivedColumn
        v-for="alias of derivedColumns[TOP_LEVEL_DERIVED_COLS]"
        :alias="alias"
        :key="alias"
      />
      <TreeProperty
        v-for="propertyType in propertyTypes"
        :property-type="propertyType"
        :derived-aliases="derivedColumns[propertyType] || []"
        :concept-path="path"
        :key="propertyType"
      />
      <div class="mt-10" v-if="children.length > 0">
        <TreeConcept v-for="child of children" :path="child.path" :key="child.key" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { LinkDescriptor } from "@/common/lib/graph";
import Disclosure from "@/common/components/Disclosure.vue";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { childPaths, expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { groupBy, isEqual, isString, last, mapValues, sortBy } from "lodash";
import { computed, toRefs } from "vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import TreeProperty from "./TreeProperty.vue";
import { underlyingPropertyTypes } from "@/common/lib/derived";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";

const props = defineProps<{
  path: ExploreTreePath;
}>();
const { path } = toRefs(props);

const exploreStore = useExploreStore();
const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);

const conceptType = computed(() => last(path.value)!.concept_type);
const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const isRoot = computed(() => path.value.length === 1);

const propertyTypes = computed(function () {
  const properties = getConceptsOfType(conceptType.value)[0].properties || [];
  return sortBy(
    properties.map((p) => p.type),
    (p) => typeLabel(p).trim()
  );
});

const linkDescription = computed(function () {
  const { elidedRoleType, link_descriptor } = last(path.value) ?? {};
  if (elidedRoleType) {
    const elidedLabel = typeLabel(elidedRoleType);
    if (link_descriptor === LinkDescriptor.RelatedTo) return `As ${elidedLabel}:`;
    if (link_descriptor === LinkDescriptor.RoleOf) return `${elidedLabel}:`;
  }
  if (link_descriptor === LinkDescriptor.AsA) return "As";
  if (link_descriptor === LinkDescriptor.RoleOf) return "Role of";
  return null;
});

const children = computed(function () {
  return childPaths(path.value).map((path) => ({
    path,
    key: JSON.stringify(last(path)),
  }));
});

const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.ConceptType, {
    conceptPath: path.value,
  })
);

const TOP_LEVEL_DERIVED_COLS = "";

const derivedColumns = computed(function () {
  const qpath = expandTreePath(path.value);
  const cols = exploreStore
    .query!.columns.filter((column) => isEqual(column.path, qpath))
    .filter((column) => !isString(column.property_type)); // Derived only
  const groups = groupBy(cols, function (column) {
    const props = underlyingPropertyTypes(column.property_type);
    return props.length != 1 ? TOP_LEVEL_DERIVED_COLS : props[0];
  });
  return mapValues(groups, (cols) => cols.map((col) => col.alias));
});

const isExpanded = computed(() => isRoot.value || exploreStore.isPathExpanded(path.value));

const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function toggleExpanded() {
  exploreStore.togglePathExpanded(path.value);
}
</script>
