<template>
  <div v-if="isEditing">
    {{ opDescription }}
    <InPlaceEdit
      title="Substring matches (one value per line)"
      @cancel="stopEditing"
      @commit="commit"
      :valid="isValid"
    >
      <textarea
        v-model="valueUnderEdit"
        ref="valueInputEl"
        class="w-full border-transparent bg-transparent"
      ></textarea>

      <Select
        v-model="matchValue"
        :options="textFilterMatchOptions"
        :option-label="optionLabel"
        :clearable="false"
      />
      <Checkbox label="Ignore case?" v-model="caseInsensitiveValue" />
    </InPlaceEdit>
  </div>

  <div
    v-else
    class="cursor-pointer truncate border-b border-dotted border-b-transparent font-bold hover:border-b-orange"
    @click="startEditing"
  >
    {{ opDescription }}
    {{ valueList }}
  </div>
</template>

<script setup lang="ts">
import InPlaceEdit from "@/common/components/InPlaceEdit.vue";
import { TextFilterMatch, textFilterMatchOptions } from "@/common/lib/query";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import Select from "@/common/components/Select.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import { QueryFilter } from "@/common/lib/query";
import { TextFilter } from "@/common/lib/fetchApi";

const props = defineProps<{ filter: QueryFilter<TextFilter> }>();
const { filter } = toRefs(props);

const valueUnderEdit: Ref<string | null> = ref(null);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const matchValue = ref<TextFilterMatch>(TextFilterMatch.Full);
const caseInsensitiveValue = ref(true);
const emit = defineEmits(["toggleEditing"]);

interface TextFilterMatchDetails {
  label: string;
  description: string;
  negatedDescription: string;
}

function optionDetails(option: TextFilterMatch): TextFilterMatchDetails {
  switch (option) {
    case TextFilterMatch.Contain:
      return {
        label: "Contains",
        description: "contains",
        negatedDescription: "doesn't contain",
      };
    case TextFilterMatch.Start:
      return {
        label: "Starts with",
        description: "starts with",
        negatedDescription: "doesn't start with",
      };
    case TextFilterMatch.End:
      return {
        label: "Ends with",
        description: "ends with",
        negatedDescription: "doesn't end with",
      };
    case TextFilterMatch.Full:
      return {
        label: "Exact match",
        description: "exactly matches",
        negatedDescription: "doesn't exactly match",
      };
  }
}

const opDescription = computed(function () {
  const negated = filter.value.negated;
  let desc = "";
  const details = optionDetails(matchValue.value);
  desc = negated ? details.negatedDescription : details.description;
  if (filter.value.values.length > 1 || isEditing.value) {
    desc = desc + " any of";
  }
  return desc;
});

function optionLabel(option: TextFilterMatch) {
  return optionDetails(option).label;
}

const isEditing = computed(() => valueUnderEdit.value !== null);
const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function startEditing() {
  if (!isEditing.value)
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  emit("toggleEditing", true);
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({
    value: toValue(v),
    match: matchValue.value,
    case_sensitive: !caseInsensitiveValue.value,
  }));
  stopEditing();
  exploreStore.load();
}

function stopEditing() {
  valueUnderEdit.value = null;
  emit("toggleEditing", false);
}

onMounted(function () {
  if (filter.value.values.length === 0) {
    startEditing();
    nextTick(() => valueInputEl.value?.focus());
  }
});
</script>
