<template>
  <div class="input peer flex flex-col items-start self-stretch">
    <label
      v-if="label && (value || placeholder)"
      class="flex items-start gap-5 pb-5 pl-10 pr-0 pt-0 text-xs font-bold text-gray-500 peer-focus:text-orange"
      :class="requiredText"
      >{{ label }}</label
    >
    <v-select
      class="dropdown-options self-stretch"
      :options="options"
      v-model="value"
      :placeholder="placeholder || label"
      :label="isString(optionLabel) ? optionLabel : undefined"
      :get-option-label="isFunction(optionLabel) ? optionLabel : undefined"
      :reduce="optionValue"
      :disabled="disabled"
      :clearable="clearable"
      :multiple="multiple"
    >
      <template v-slot:option="option: any">
        <slot name="option-data" v-bind="option"></slot>
      </template>
      <template v-slot:selected-option="option: any">
        <slot name="option-data" v-bind="option"></slot>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import vSelect from "vue-select";
import { isString, isFunction } from "lodash";

const props = withDefaults(
  defineProps<{
    label?: string;
    modelValue?: string | object;
    placeholder?: string;
    disabled?: boolean;
    options: (string | object)[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionLabel?: string | ((option: any) => string);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionValue?: (o: any) => string;
    required?: boolean;
    clearable?: boolean;
    multiple?: boolean;
  }>(),
  {
    clearable: true,
    multiple: false,
  }
);
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const requiredText = computed(() => {
  if (props.required) {
    return ':after:content-["*"] after:text-orange';
  } else {
    return "";
  }
});
</script>
